import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import getActionData from './helpers/getActionData';

export default function Action({ action, showJobApplicationInfo }) {
  const { t, i18n } = useTranslation();

  const { title, text, date } = getActionData(action, t, i18n);
  return (
    <div
      className={classes.Action}
      onClick={() => {
        if (action?.jobApplication?.id) {
          showJobApplicationInfo(action.jobApplication.id);
        }
      }}
    >
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.date}>{date}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
}
