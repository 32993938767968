import { getEventTitleVariables } from '../../../../../hooks/useGetNotificationData';

const actionsWithDates = [
  'JobSeekerInterviewScheduled',
  'InterviewFirstReminderJobSeeker',
  'InterviewSecondReminderJobSeeker',
  'JobSeekerInterviewRescheduled',
  'OnboardingSecondReminderJobSeeker',
  'OnboardingFirstReminderJobSeeker',
  'JobSeekerOnboardingScheduled',
  'JobSeekerOnboardingRescheduled',
  'JoiningSecondReminderJobSeeker',
  'JoiningFirstReminderJobSeeker',
  'JobSeekerJoiningScheduled',
  'JobSeekerJoiningRescheduled',
];

function createEventDate(action) {
  if (actionsWithDates.includes(action.name)) {
    const { date, time } = getEventTitleVariables(action);
    return `${date} ${time}`;
  }

  return null;
}

export default function getActionData(action, t, i18n) {
  const titleKey = `components.MyDashboard.Actions.actionsData.${action.name}.title`;
  const textKey = `components.MyDashboard.Actions.actionsData.${action.name}.text`;

  const title = i18n.exists(titleKey) ? t(titleKey) : action.category;
  const text = i18n.exists(textKey)
    ? t(textKey, {
        jobTitle: action.jobApplication?.jobPost?.jobTitle,
        brandName: action.messageInfo,
        messageInfo: action.messageInfo,
      })
    : action.eventNote;
  const date = createEventDate(action);

  return {
    title,
    date,
    text,
  };
}
