/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import {
  NavLink,
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Notifications from '../../../components/Notifications';
import SignInModal from '../../../modals/SignInModal';
import MobileMenu from './MobileMenu';
import SearchBar from '../../../components/SearchBar';
import Languages from './Languages';
import Links from './Links';

import UserService from '../../../services/UserService';
import getResizedImageUrl from '../../../helpers/getResizedImageUrl';
import isInIframe from '../../../helpers/isInIframe';
import { UiContext } from '../../../context/UiContext';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { clearAuthData, hasAuthData } from '../../../helpers/authStorage';
import classes from './styles.module.scss';

let timer;

export default function Header({ setIsUserMenuVisible, logo, landing }) {
  const [isSignInModalVisible, setIsSigninModalVisible] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchBarWidth, setSearchBarWidth] = useState(322);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: i18next.language.toLocaleUpperCase(),
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search');
  const location = useLocation();

  const queryClient = useQueryClient();

  const {
    isAuthenticated,
    setIsAuthenticated,
    isNotificationsPopupVisible,
    setIsNotificationsPopupVisible,
  } = useContext(UiContext);
  const isIframe = isInIframe();

  const mainNavRef = useRef();
  const searchButtonRef = useRef();
  const notificationsRef = useRef();
  const myNotificationsButtonRef = useRef();
  const headerRef = useRef();

  useOnClickOutside(
    notificationsRef,
    () => setIsNotificationsPopupVisible(false),
    myNotificationsButtonRef
  );

  const isAuth = hasAuthData();

  const {
    data: user,
    refetch,
    isFetched,
  } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: isAuth,
  });

  const updateUserProlfileLanguage = useCallback(
    async (lang) => {
      if (!lang) {
        return;
      }

      try {
        await UserService.updateMe({
          ...user.userProfile,
          languageCode: lang.languageCode,
        });

        refetch();
      } catch (error) {
        console.log(error);
      }
    },
    [refetch, user?.userProfile]
  );

  const switchLanguage = useCallback(
    async (lang) => {
      if (!lang) {
        return;
      }

      try {
        i18next.changeLanguage(lang.name.toLowerCase());
        setSelectedLanguage(lang);

        if (isAuth && user) {
          updateUserProlfileLanguage(lang);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [isAuth, updateUserProlfileLanguage, user]
  );

  // Set language from user profile
  /*  useEffect(() => {
    if (isAuth && isFetched && user?.userProfile?.languageCode && isFetched) {
      i18next.changeLanguage(
        user.userProfile.languageCode === 'tr-TR' ? 'tr' : 'en'
      );
      setSelectedLanguage({
        name: user.userProfile.languageCode === 'en-US' ? 'EN' : 'TR',
      });
    } else if (
      isFetched &&
      isAuth &&
      !user?.userProfile?.languageCode &&
      user?.userProfile?.firstName
    ) {
      updateUserProlfileLanguage({
        languageCode: i18next.language === 'tr' ? 'tr-TR' : 'en-US',
      });
    }
  }, [
    user?.userProfile?.languageCode,
    isFetched,
    switchLanguage,
    isAuth,
    updateUserProlfileLanguage,
    user?.userProfile?.firstName,
  ]); */

  // Update profile language if user is authenticated and theirs language is different from the current language
  useEffect(() => {
    if (
      isFetched &&
      isAuth &&
      user?.userProfile?.firstName &&
      !user?.userProfile?.languageCode?.includes(i18next.language)
    ) {
      updateUserProlfileLanguage({
        languageCode: i18next.language === 'tr' ? 'tr-TR' : 'en-US',
      });
    }
  }, [
    user?.userProfile?.languageCode,
    isFetched,
    isAuth,
    updateUserProlfileLanguage,
    user?.userProfile?.firstName,
  ]);

  const setSearchTerm = useCallback(
    (term) => {
      if (term?.trim?.() && term !== searchTerm) {
        navigate(`/search-results?search=${term}&fromHeader=true`, {
          replace: location.pathname === '/search-results',
        });
      }
    },
    [location.pathname, navigate, searchTerm]
  );

  useEffect(() => {
    if (searchParams.get('signin')) {
      setIsSigninModalVisible(true);
    }
  }, [searchParams]);

  const logout = () => {
    clearAuthData();
    setIsAuthenticated(false);
    queryClient.invalidateQueries();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (isTabletOrMobile) {
      return;
    }

    const calculateSearchBarWidth = () => {
      const { right: mainNavRight } =
        mainNavRef.current?.getBoundingClientRect();

      const { right: searchButtonRight } =
        searchButtonRef.current?.getBoundingClientRect();

      setSearchBarWidth(searchButtonRight - mainNavRight);
    };

    window.addEventListener('resize', calculateSearchBarWidth);

    setTimeout(() => {
      calculateSearchBarWidth();
    }, 0);

    return () => window.removeEventListener('resize', calculateSearchBarWidth);
  }, [isTabletOrMobile, language, isLogoLoaded]);

  // If landing is true, header should dissapear on scroll and reapper after 1000 ms of no scrolling
  // ! Disabled for now !
  useEffect(() => {
    if (!landing) {
      return;
    }

    const handleScroll = () => {
      setIsScrolling(true);

      clearTimeout(timer);

      timer = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [landing]);

  let logoUrl = null;

  if (logo) {
    logoUrl = logo.startsWith('data:image/')
      ? logo
      : getResizedImageUrl({
          url: `brand/${logo.split('/')?.pop()}`,
          width: 165,
        });
  }

  return (
    <header
      ref={headerRef}
      id="mainHeader"
      className={classNames(classes.Header, {
        [classes.iframe]: isIframe,
        [classes.landing]: landing,
        [classes.hidden]: isScrolling,
      })}
    >
      <Link to="/" className={classes.logo}>
        <img src={logoUrl} alt="Logo" onLoad={() => setIsLogoLoaded(true)} />
      </Link>
      <nav className={classes.nav}>
        <ul
          className={classNames(classes.mainNav, classes.list)}
          ref={mainNavRef}
        >
          <Links
            myNotificationsButtonRef={myNotificationsButtonRef}
            setIsNotificationsPopupVisible={setIsNotificationsPopupVisible}
          />
        </ul>
      </nav>
      {isTabletOrMobile ? (
        <MobileMenu
          isSearchEnabled={isSearchEnabled}
          setIsSearchEnabled={setIsSearchEnabled}
          setSearchTerm={setSearchTerm}
          showSignInModal={() => setIsSigninModalVisible(true)}
          logout={logout}
          setIsUserMenuVisible={setIsUserMenuVisible}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={switchLanguage}
          logoUrl={logoUrl}
        />
      ) : (
        <ul className={classNames(classes.userMenu, classes.list)}>
          <li className={classes.listItem}>
            {isSearchEnabled && (
              <div className={classes.search}>
                <SearchBar
                  searchName="MainSearch"
                  setSearchTerm={setSearchTerm}
                  width={searchBarWidth}
                  onCloseSearchClick={() => setIsSearchEnabled(false)}
                />
              </div>
            )}
            <button
              ref={searchButtonRef}
              type="button"
              className={classes.searchButton}
              onClick={() => setIsSearchEnabled(true)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                  stroke="#242833"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 22L20 20"
                  stroke="#242833"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </li>
          {isAuthenticated && !isIframe && (
            <li className={classes.listItem}>
              <NavLink to="/profile">
                <span className={classes.navLink}>
                  {t('components.Header.profile')}
                </span>
              </NavLink>
            </li>
          )}
          {!isIframe && (
            <li className={classes.listItem}>
              {isAuthenticated ? (
                <button
                  type="button"
                  className={classes.signInLink}
                  onClick={logout}
                >
                  <span className={classes.navLink}>
                    {t('components.Header.signOut')}
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  className={classes.signInLink}
                  onClick={() => setIsSigninModalVisible(true)}
                >
                  <span className={classes.navLink}>
                    {t('components.Header.signIn')}
                  </span>
                </button>
              )}
            </li>
          )}
          <li className={classes.listItem}>
            <Languages
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={switchLanguage}
            />
          </li>
        </ul>
      )}
      <SignInModal
        show={isSignInModalVisible}
        handleClose={useCallback(() => setIsSigninModalVisible(false), [])}
      />
      {isNotificationsPopupVisible && (
        <Notifications reference={notificationsRef} />
      )}
    </header>
  );
}
