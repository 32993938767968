import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Action from './Action';

import classes from './styles.module.scss';
import PublicService from '../../../services/PublicService';

export default function Actions({ showJobApplicationInfo }) {
  const { t } = useTranslation();

  const { data: notifications } = useQuery({
    queryKey: ['notifications'],
    queryFn: PublicService.getNotifications,
  });

  const actions = notifications?.filter(
    (notification) => notification.type === 'Action'
  );

  console.log(actions);

  return (
    <div className={classes.Actions}>
      <header className={classes.header}>
        <h2>{t('components.MyDashboard.Actions.title')}</h2>
        <div className={classes.count}>{actions?.length}</div>
      </header>
      <div className={classes.actions}>
        {actions && actions.length === 0 ? (
          <div className={classes.noActions}>
            <span>{t('components.MyDashboard.Actions.noData')}</span>
          </div>
        ) : (
          actions?.map((action) => {
            return (
              <Action
                key={action.id}
                action={action}
                showJobApplicationInfo={showJobApplicationInfo}
              />
            );
          })
        )}
      </div>
    </div>
  );
}
