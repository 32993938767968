import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import i18n from '../i18n';
import notificationStorage from '../helpers/NotificationStorage';

import useUiContext from './useUiContext';
import {
  getTime,
  getTimezoneDate,
  getTimezoneTime,
} from '../helpers/timezones';
import PublicService from '../services/PublicService';
// import useJobInviteHandlers from './useJobInviteHandlers';

const t = i18n.t.bind(i18n);

const eventMapKeys = {
  JobSeekerInterviewScheduled: {
    array: 'jobApplicationInterviews',
    date: 'interviewDate',
  },
  JobSeekerInterviewRescheduled: {
    array: 'jobApplicationInterviews',
    date: 'interviewDate',
  },
  InterviewFirstReminderJobSeeker: {
    array: 'jobApplicationInterviews',
    date: 'interviewDate',
  },
  InterviewSecondReminderJobSeeker: {
    array: 'jobApplicationInterviews',
    date: 'interviewDate',
  },
  JobSeekerOnboardingScheduled: {
    array: 'jobApplicationOnboardings',
    date: 'onboardingDate',
  },
  JobSeekerOnboardingRescheduled: {
    array: 'jobApplicationOnboardings',
    date: 'onboardingDate',
  },
  OnboardingFirstReminderJobSeeker: {
    array: 'jobApplicationOnboardings',
    date: 'onboardingDate',
  },
  OnboardingSecondReminderJobSeeker: {
    array: 'jobApplicationOnboardings',
    date: 'onboardingDate',
  },
  JobSeekerJoiningScheduled: {
    array: 'jobApplicationJoinings',
    date: 'joiningDate',
  },
  JobSeekerJoiningRescheduled: {
    array: 'jobApplicationJoinings',
    date: 'joiningDate',
  },
  JoiningFirstReminderJobSeeker: {
    array: 'jobApplicationJoinings',
    date: 'joiningDate',
  },
  JoiningSecondReminderJobSeeker: {
    array: 'jobApplicationJoinings',
    date: 'joiningDate',
  },
};

function calculateAssessmentDaysLeft(notification) {
  if (
    !notification?.jobApplication?.assignedAssessments ||
    !notification?.jobApplication?.assignedAssessments?.length
  ) {
    return 0;
  }

  const firstExpiringAssessment =
    notification.jobApplication.assignedAssessments.reduce((min, current) => {
      return current.limit < min.limit ? current : min;
    });

  const expiresAt = moment(firstExpiringAssessment.assignedAt).add(
    firstExpiringAssessment.limit,
    'days'
  );

  const daysLeft = moment(expiresAt).diff(moment(), 'days', true);

  return daysLeft > 0
    ? Math.min(Math.ceil(daysLeft), firstExpiringAssessment.limit)
    : 0;
}

function getEventDateText(notification) {
  const arrayKey = eventMapKeys[notification.name]?.array;
  const dateKey = eventMapKeys[notification.name]?.date;

  if (!arrayKey || !dateKey) {
    return '';
  }

  return `${moment(
    getTimezoneDate(notification.jobApplication?.[arrayKey]?.[0]?.[dateKey])[0]
  ).format('Do MMM YYYY')} ${getTime(
    notification.jobApplication?.[arrayKey]?.[0]?.startTime
  )} - ${getTime(notification.jobApplication?.[arrayKey]?.[0]?.endTime)}`;
}

export function getEventTitleVariables(notification) {
  const arrayKey = eventMapKeys[notification.name]?.array;
  const dateKey = eventMapKeys[notification.name]?.date;

  console.log(arrayKey, dateKey);

  if (!arrayKey || !dateKey) {
    return {};
  }

  return {
    jobTitle: notification?.jobApplication?.jobPost?.jobTitle,
    messageInfo: notification?.messageInfo,
    date: moment(
      getTimezoneDate(
        notification.jobApplication?.[arrayKey]?.[0]?.[dateKey]
      )[0]
    ).format('Do MMM YYYY'),
    time: getTimezoneTime(
      notification.jobApplication?.[arrayKey]?.[0]?.startTime
    ),
    type:
      notification.jobApplication?.[arrayKey]?.[0]?.type === 'InPerson'
        ? 'in-person'
        : 'online',
  };
}

function createDefaultNotificationData(notification) {
  return {
    title: notification.eventNote,
    text: notification.jobApplication?.jobPost?.jobTitle,
    buttonsData: {
      mainButtonTitle: t('helpers.getNotificationButtonsData.review'),
      secondaryButtonTitle: '',
      onMainButtonClick: () => {
        notificationStorage.addNotificationId(notification.id);
      },
      mainButtonWidth: 112,
    },
    isOutdated: false,
  };
}

function useGetJobNotificationData(
  notification,
  refetchNotifications,
  navigate,
  closePopup,
  showMessages
) {
  const defaultNotificationData = createDefaultNotificationData(notification);

  const confirmInterviewButtonsData = {
    mainButtonTitle: t('common.accept'),
    secondaryButtonTitle: t('common.decline'),
    onMainButtonClick: async () => {
      try {
        await PublicService.acceptInterviewSchedule({
          jobApplicationId: notification?.jobApplication?.id,
          interviewId:
            notification?.jobApplication?.jobApplicationInterviews?.[0]?.id,
        });
        refetchNotifications?.();
      } catch (error) {
        console.log(error);
      }
    },
    onSecondaryButtonClick: async () => {
      try {
        await PublicService.declineInterviewSchedule({
          jobApplicationId: notification?.jobApplication?.id,
          interviewId:
            notification?.jobApplication?.jobApplicationInterviews?.[0]?.id,
        });
        refetchNotifications?.();
      } catch (error) {
        console.log(error);
      }
    },
  };

  const scheduledOnboardingButtonsData = {
    mainButtonTitle: t('common.accept'),
    secondaryButtonTitle: t('common.decline'),
    onMainButtonClick: async () => {
      try {
        await PublicService.acceptOnboarding({
          jobApplicationId: notification?.jobApplication?.id,
          onboardingId:
            notification?.jobApplication?.jobApplicationOnboardings?.[0]?.id,
        });
        refetchNotifications?.();
      } catch (error) {
        console.log(error);
      }
    },
    onSecondaryButtonClick: async () => {
      try {
        await PublicService.declineOnboarding({
          jobApplicationId: notification?.jobApplication?.id,
          onboardingId:
            notification?.jobApplication?.jobApplicationOnboardings?.[0]?.id,
        });
        refetchNotifications?.();
      } catch (error) {
        console.log(error);
      }
    },
  };

  const scheduledStartDateButtonsData = {
    name: 'JoiningScheduled',
    mainButtonTitle: t('common.accept'),
    secondaryButtonTitle: t('common.decline'),
    onMainButtonClick: async () => {
      try {
        await PublicService.acceptJoining({
          jobApplicationId: notification?.jobApplication?.id,
          joiningId:
            notification?.jobApplication?.jobApplicationJoinings?.[0]?.id,
        });
        refetchNotifications?.();
      } catch (error) {
        console.log(error);
      }
    },
    onSecondaryButtonClick: async () => {
      try {
        await PublicService.declineJoining({
          jobApplicationId: notification?.jobApplication?.id,
          joiningId:
            notification?.jobApplication?.jobApplicationJoinings?.[0]?.id,
        });
        refetchNotifications?.();
      } catch (error) {
        console.log(error);
      }
    },
  };

  const newNotificationData = {
    Candidate: {
      JobSeekerWelcome: {
        title: t('helpers.useGetJobNotificationData.JobSeekerWelcome.0', {
          brandName: notification?.messageInfo,
        }),
        text: t('helpers.useGetJobNotificationData.JobSeekerWelcome.1'),
        isOutdated: false,
      },
      ExternalCandidateProfileCreated: {
        title: t(
          'helpers.useGetJobNotificationData.ExternalCandidateProfileCreated',
          {
            messageInfo: notification?.messageInfo,
          }
        ),
        text: `${moment(notification.createdAt).format(
          'Do MMM YYYY'
        )} ${getTime(notification.createdAt)}`,
        buttonsData: {
          mainButtonTitle: t('helpers.useGetJobNotificationData.Review'),
          secondaryButtonTitle: '',
          onMainButtonClick: () => {
            notificationStorage.addNotificationId(notification.id);
            navigate('/profile');
            closePopup();
          },
          mainButtonWidth: 112,
        },
        isOutdated: false,
      },
    },
    New: {
      InvitationCreated: {
        title: t('helpers.useGetJobNotificationData.InvitationCreated', {
          messageInfo: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: defaultNotificationData.buttonsData,
      },
      InvitationSecondReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.InvitationSecondReminderJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          mainButtonTitle: t('helpers.getNotificationButtonsData.actionNow'),
        },
      },
      InvitationExpiredJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.InvitationExpiredJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JobSeekerApplied: {
        title: t('helpers.useGetJobNotificationData.JobSeekerApplied', {
          messageInfo: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JobSeekerDropOff: {
        title: t('helpers.useGetJobNotificationData.JobSeekerDropOff', {
          messageInfo: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          onMainButtonClick: () => {
            notificationStorage.addNotificationId(notification.id);
            navigate(`/jobs/${notification.jobPost.id}`);
            closePopup();
          },
        },
        isOutdated: false,
      },
      JobSeekerDropOffReason: {
        title: t('helpers.useGetJobNotificationData.JobSeekerDropOffReason', {
          messageInfo:
            notification.jobPost?.jobTitle ||
            notification.jobApplication?.jobPost?.jobTitle ||
            'job',
        }),
        text:
          notification.jobPost?.jobTitle ||
          notification.jobApplication?.jobPost?.jobTitle ||
          'job',
        buttonsData: defaultNotificationData.buttonsData,
      },
    },
    Screen: {
      JobSeekerAssessmentAssigned: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerAssessmentAssigned',
          {
            messageInfo: notification?.jobPost?.jobTitle,
            assessmentDaysLeft: calculateAssessmentDaysLeft(notification),
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: defaultNotificationData.buttonsData,
      },
      JobSeekerAssessmentCompleted: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerAssessmentCompleted',
          {
            messageInfo: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JobSeekerAssessmentExpired: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerAssessmentExpired',
          {
            jobTitle: notification?.jobPost?.jobTitle,
            messageInfo: notification?.messageInfo,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      AssessmentSecondReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.AssessmentSecondReminderJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          mainButtonTitle: t('helpers.getNotificationButtonsData.actionNow'),
        },
      },
      AssessmentFirstReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.AssessmentFirstReminderJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          mainButtonTitle: t('helpers.getNotificationButtonsData.actionNow'),
        },
      },
    },
    Interview: {
      JobSeekerInterviewScheduled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerInterviewScheduled',
          { ...getEventTitleVariables(notification) }
        ),
        text: getEventDateText(notification),
        buttonsData: confirmInterviewButtonsData,
      },
      JobSeekerInterviewCanceled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerInterviewCanceled',
          {
            jobTitle: notification?.jobPost?.jobTitle,
            messageInfo: notification?.messageInfo,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      InterviewAcceptedJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.InterviewAcceptedJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
            messageInfo: notification?.messageInfo,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JobSeekerInterviewRescheduled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerInterviewRescheduled',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: getEventDateText(notification),
        buttonsData: confirmInterviewButtonsData,
      },
      InterviewFirstReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.InterviewFirstReminderJobSeeker',
          { ...getEventTitleVariables(notification) }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: confirmInterviewButtonsData,
      },
      InterviewSecondReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.InterviewSecondReminderJobSeeker',
          { ...getEventTitleVariables(notification) }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          mainButtonTitle: t('helpers.getNotificationButtonsData.actionNow'),
        },
      },
      InterviewExpiredJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.InterviewExpiredJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
    },
    Job: {
      JobSeekerJobPostClosed: {
        title: t('helpers.useGetJobNotificationData.JobSeekerJobPostClosed', {
          jobTitle: notification?.jobPost?.jobTitle,
          messageInfo: notification?.messageInfo,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
    },
    Onboarding: {
      JobSeekerOnboardingScheduled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerOnboardingScheduled',
          {
            ...getEventTitleVariables(notification),
          }
        ),
        text: getEventDateText(notification),
        buttonsData: scheduledOnboardingButtonsData,
      },
      JobSeekerOnboardingCanceled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerOnboardingCanceled',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JobSeekerOnboardingRescheduled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerOnboardingRescheduled',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: getEventDateText(notification),
        buttonsData: scheduledOnboardingButtonsData,
      },
      JobSeekerJoiningScheduled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerJoiningScheduled',
          {
            ...getEventTitleVariables(notification),
          }
        ),
        text: getEventDateText(notification),
        buttonsData: scheduledStartDateButtonsData,
      },
      JobSeekerJoiningCanceled: {
        title: t('helpers.useGetJobNotificationData.JobSeekerJoiningCanceled', {
          jobTitle: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JobSeekerJoiningRescheduled: {
        title: t(
          'helpers.useGetJobNotificationData.JobSeekerJoiningRescheduled',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: getEventDateText(notification),
        buttonsData: scheduledStartDateButtonsData,
      },
      OnboardingSecondReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OnboardingSecondReminderJobSeeker',
          {
            ...getEventTitleVariables(notification),
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          mainButtonTitle: t('helpers.getNotificationButtonsData.actionNow'),
        },
      },
      OnboardingFirstReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OnboardingFirstReminderJobSeeker',
          {
            ...getEventTitleVariables(notification),
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: scheduledStartDateButtonsData,
      },
      OnboardingExpiredJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OnboardingExpiredJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      OnboardingChangeRequestJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OnboardingChangeRequestJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JoiningSecondReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.JoiningSecondReminderJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          mainButtonTitle: t('helpers.getNotificationButtonsData.actionNow'),
        },
      },
      JoiningFirstReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.JoiningFirstReminderJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: scheduledStartDateButtonsData,
      },
      JoiningCompletedJobSeeker: {
        title: t('helpers.useGetJobNotificationData.JoiningCompletedJobSeeker'),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JoiningExpiredJobSeeker: {
        title: t('helpers.useGetJobNotificationData.JoiningExpiredJobSeeker', {
          jobTitle: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      JoiningChangeRequestJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.JoiningChangeRequestJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
    },
    Chat: {
      JobSeekerNewMessage: {
        title: t('helpers.useGetJobNotificationData.JobSeekerNewMessage', {
          jobTitle: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          mainButtonTitle: t('helpers.getNotificationButtonsData.respond'),
          secondaryButtonTitle: '',
          onMainButtonClick: () => {
            showMessages(notification.jobApplication);
          },
        },
      },
    },
    Offer: {
      OfferCreated: {
        title: t('helpers.useGetJobNotificationData.OfferCreated', {
          jobTitle: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: defaultNotificationData.buttonsData,
      },
      OfferAcceptedJobSeeker: {
        title: t('helpers.useGetJobNotificationData.OfferAcceptedJobSeeker', {
          jobTitle: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      OfferCounteredAcceptedJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OfferCounteredAcceptedJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      OfferCounteredDeclinedJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OfferCounteredDeclinedJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
      OfferSecondReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OfferSecondReminderJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          ...defaultNotificationData.buttonsData,
          mainButtonTitle: t('helpers.getNotificationButtonsData.actionNow'),
        },
      },
      OfferFirstReminderJobSeeker: {
        title: t(
          'helpers.useGetJobNotificationData.OfferFirstReminderJobSeeker',
          {
            jobTitle: notification?.jobPost?.jobTitle,
          }
        ),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: defaultNotificationData.buttonsData,
      },
      OfferExpiredJobSeeker: {
        title: t('helpers.useGetJobNotificationData.OfferExpiredJobSeeker', {
          jobTitle: notification?.jobPost?.jobTitle,
        }),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: null,
      },
    },
  };

  const data = newNotificationData[notification.category]?.[notification.name];

  if (data && data.isOutdated === undefined) {
    const isOutdated =
      notification.jobApplication?.status !==
        notification.onEventJobApplicationStatus ||
      notification.jobApplication?.subStatus !==
        notification.onEventJobApplicationSubStatus;

    data.isOutdated = isOutdated;
  } else {
    const isOutdated = notificationStorage.readNotificationIds.includes(
      notification.id
    );

    defaultNotificationData.isOutdated = isOutdated;
  }

  return data || defaultNotificationData;
}

export default function useGetNotificationData(
  notification,
  refetchNotifications,
  showMessages
) {
  const navigate = useNavigate();
  const { setIsNotificationsPopupVisible } = useUiContext();

  const closePopup = () => {
    setIsNotificationsPopupVisible(false);
  };

  const jobNotificationData = useGetJobNotificationData(
    notification,
    refetchNotifications,
    navigate,
    closePopup,
    showMessages
  );

  // console.log(jobNotificationData);

  return jobNotificationData;
}
