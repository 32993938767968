import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

const isRejected = (currentStep, index, status, subStatus) => {
  if (subStatus?.toLowerCase().includes('decline')) {
    return true;
  }

  if (status === 'Screen' && subStatus === 'Incomplete') {
    return true;
  }

  if (subStatus === 'Withdrawn' || subStatus === 'NoShow') {
    return true;
  }

  return (
    index === currentStep &&
    (subStatus === 'Rejected' || subStatus === 'Failed')
  );
};

export default function Status({
  status,
  subStatus,
  assessment,
  hasAssessment,
}) {
  const { t } = useTranslation();

  const hasAssesmentExpired = false;

  const isFinish = subStatus === 'Joined';
  let isCurrentStepFinished = false;

  const steps = [
    {
      status: 'New',
      title: t('components.MyDashboard.JobApplicationInfo.Status.review'),
    },
    {
      status: 'Screen',
      title: t('components.MyDashboard.JobApplicationInfo.Status.assessment'),
    },
    {
      status: 'Interview',
      title: t('components.MyDashboard.JobApplicationInfo.Status.interview'),
    },
    {
      status: 'Offer',
      title: t('components.MyDashboard.JobApplicationInfo.Status.offer'),
    },
    {
      status: 'Onboard',
      title: t('components.MyDashboard.JobApplicationInfo.Status.onboarding'),
    },
  ];

  const stepsWithoutAssessment = [
    {
      status: 'New',
      title: t('components.MyDashboard.JobApplicationInfo.Status.review'),
    },
    {
      status: 'Interview',
      title: t('components.MyDashboard.JobApplicationInfo.Status.interview'),
    },
    {
      status: 'Offer',
      title: t('components.MyDashboard.JobApplicationInfo.Status.offer'),
    },
    {
      status: 'Onboard',
      title: t('components.MyDashboard.JobApplicationInfo.Status.onboarding'),
    },
  ];

  const currentSteps = hasAssessment ? steps : stepsWithoutAssessment;

  let currentStep = currentSteps.findIndex((step) => step.status === status);

  if (currentStep === -1) {
    currentStep = 0;
  }

  if (
    (status === 'Offer' && subStatus === 'Pending') ||
    (status === 'Onboard' && subStatus === 'Pending')
  ) {
    currentStep -= 1;
    isCurrentStepFinished = true;
  }

  if (status === 'Interview' && subStatus === 'Pending' && !hasAssessment) {
    currentStep = 0;
  } else if (
    status === 'Interview' &&
    subStatus === 'Pending' &&
    hasAssessment
  ) {
    currentStep -= 1;
    isCurrentStepFinished = true;
  }

  if (status === 'Interview' && subStatus === 'Passed') {
    isCurrentStepFinished = true;
  }

  if (status === 'Offer' && subStatus === 'Accepted') {
    isCurrentStepFinished = true;
  }

  if (status === 'Onboard' && subStatus === 'StartConfirmed') {
    isCurrentStepFinished = true;
  }

  return (
    <div
      className={classNames(classes.Status, {
        [classes.withoutAssessment]: !hasAssessment,
      })}
    >
      <div className={classes.steps}>
        <div className={classes.line} />
        {currentSteps.map((step, index) => {
          return (
            <div
              key={step.status}
              className={classNames(classes.step, {
                [classes.active]: index === currentStep,
                [classes.completed]: index < currentStep || isFinish,
                [classes.finished]:
                  index === currentStep && isCurrentStepFinished,
                [classes.first]: index === 0,
                [classes.rejected]:
                  isRejected(
                    currentStep,
                    index,
                    status,
                    subStatus,
                    assessment
                  ) || hasAssesmentExpired === true,
              })}
            >
              <div className={classes.icon}>{index + 1}</div>
              <h3>{step.title}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
}
